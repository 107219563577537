#nav-drawer-container a, #nav-drawer-container a:hover,
#nav-drawer-container a:active, #nav-drawer-container a:visited {
  color: #3D405B;
}

.nav-link a {
  color: white;
}

.nav-hover a {
  color: #E07A5F;
}

@media screen and (min-width: 65rem) {
  #nav-drawer-button {
    display: none;
  }
}

@media screen and (max-width: 65rem) {
  #nav-traditional {
    display: none;
  }
  #nav-dropdown-container {
    display: none;
  }
}
