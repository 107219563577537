body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Verdana, Geneva, sans-serif;
  color: white;
  background-color: #242943;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:hover, a:active, a:visited {
  color: white;
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.striped--near-white:nth-child(even) {
  background-color: #202040;
}
.striped--near-white:nth-child(odd) {
  background-color: #2A2A4A;
}
