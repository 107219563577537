.Home .hero {
  position: relative;
  height: 450px;
  background-position: center;
  background-repeat: repeat-x;
}

.Home .hero-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 0, 20, 0.65);
  z-index: 1;
}

.Home .hero-title {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.Home .hero-title h3 {
  font-weight: 200;
  font-size: clamp(2.5rem, -0.875rem + 8.333vw, 7rem);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.75);
}
