.HomeTile {
  position: relative;
  cursor: pointer;
}

.HomeTile .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  transition-property: opacity;
  transition-duration: 0.5s;
  z-index: 1;
}

.HomeTile .mask.fade {
  opacity: 0;
}

.HomeTile .label {
  z-index: 2;
}
